<template>
  <div class="Home" :class="{ darkback: darkmode}">
    <!--<page-header/>-->
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <section class="uk-section uk-padding-remove">
          <div class="uk-container">
            <div class="uk-text-center">
              <h4 class="qList uk-margin-small-top">{{ $t("orgaExam.results.eName") }} :
                {{ examDetail.examName }}</h4>
            </div>

            <div uk-form-custom="target: > * > span:first-child" class=" uk-margin-right">
              <div style="min-width: 200px">
                <input style="height: 40px;cursor: pointer" readonly type="text"
                       :value="activeGroup.groupName"
                       placeholder="Select group"
                       class="uk-width-1-1 uk-select">
                <div style="padding: 0px 10px;"
                     data-uk-dropdown="mode:click; pos: bottom-justify">
                  <div class="uk-search uk-search-default uk-width-1-1">
                    <span class="uk-search-icon-flip" uk-search-icon></span>
                    <input class="uk-search-input" v-model="value" type="search"
                           placeholder="Search...">
                  </div>
                  <ul style="max-height: 150px;overflow-y: auto;"
                      class="uk-nav uk-nav-default" v-if="resultQuery.length > 0">
                    <template v-for="(item, index) in resultQuery">
                      <li :key="index"
                          :class="{ 'uk-active': item.id == activeGroup.id }">
                        <a @click="selectGroup(item)" href="javascript:void(0)">{{ item.groupName }}</a>
                      </li>
                    </template>
                  </ul>
                  <ul class="uk-nav uk-nav-default uk-padding-small" v-else>
                    <li class="uk-text-center">Empty.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="uk-margin-small-right" uk-form-custom="target: > * > span:first-child">
              <select @change="statusSearch()" class="sortBy uk-select"
                      v-model="statusfinish">
                <option :value="3">{{ $t("status") }}</option>
                <option :value="0">{{ $t("beforeExam") }}</option>
                <option :value="1">{{ $t("onExam") }}</option>
                <option :value="2">{{ $t("finish") }}</option>
              </select>
              <button
                  class="uk-button uk-button-default btn-sortBy"
                  style="min-width: 150px;"
                  tabindex="-1">
                <span uk-icon="icon: chevron-down"></span>
              </button>
            </div>
<!--            <table class="uk-table uk-table-responsive">-->
<!--              <tbody>-->
<!--              <tr style="border-top: 3px solid rgb(1, 176, 255) !important;">-->
<!--                <td class="tit">{{ $t("orgaExam.results.superViser") }}</td>-->
<!--                <td class="tit2">{{ examDetail.regUser }}</td>-->
<!--                <td class="tit">{{ $t("orgaExam.results.qCount") }}</td>-->
<!--                <td class="tit2">{{ examDetail.qCount }}</td>-->
<!--                <td class="tit">{{ $t("orgaExam.results.score") }}</td>-->
<!--                <td class="tit2">{{ examDetail.totalScore }}</td>-->
<!--              </tr>-->
<!--              <tr>-->

<!--                <td class="tit">{{ $t("orgaExam.results.sLifeT") }}</td>-->
<!--                <td class="tit2">{{ examDetail.examSdate|moment }}</td>-->
<!--                <td class="tit">{{ $t("orgaExam.results.eLifeT") }}</td>-->
<!--                <td class="tit2">{{ examDetail.examEdate|moment }}</td>-->
<!--                <td class="tit">{{ $t("orgaExam.results.eTime") }}</td>-->
<!--                <td class="tit2">{{ examDetail.examTime }} {{ $t("orgaExam.results.minute") }}</td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="tit">{{ $t("beforeExam") }}</td>-->
<!--                <td class="tit2">-->
<!--                  <label class="uk-label uk-label-warning uk-text-capitalize" style="width: auto">-->
<!--                    {{ examDetail.ecodeUsed - examDetail.startedcount - examDetail.finishcount }}</label>-->
<!--                </td>-->
<!--                <td class="tit">{{ $t("onExam") }}</td>-->
<!--                <td class="tit2">-->
<!--                  <label class="uk-label uk-label-primary uk-text-capitalize">{{ examDetail.startedcount }}</label>-->
<!--                </td>-->
<!--                <td class="tit">{{ $t("finish") }}</td>-->
<!--                <td class="tit2">-->
<!--                  <label class="uk-label uk-label-success uk-text-capitalize">-->
<!--                    {{ examDetail.finishcount }}-->
<!--                  </label>-->
<!--                </td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <td class="tit">{{ $t("orgaExam.results.applic") }}</td>-->
<!--                <td class="tit2">{{ examDetail.ecodeCount }} {{ $t("orgaExam.results.per") }}</td>-->
<!--                <td class="tit">{{ $t("orgaExam.results.toEx") }}</td>-->
<!--                <td class="tit2">{{ examDetail.ecodeUsed }} {{ $t("orgaExam.results.per") }}</td>-->
<!--                &lt;!&ndash;            <td class="tit">{{$t("orgaExam.results.status")}}</td>&ndash;&gt;-->
<!--                &lt;!&ndash;            <td class="tit2"><span class="uk-text-capitalize">{{examDetail.examStatus}}</span></td>&ndash;&gt;-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
          </div>
        </section>
        <section class="uk-section uk-padding-remove uk-margin-top">
          <div class="uk-container">
            <div class="uk-overflow-auto">
              <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>
                <div class="uk-width-1-3@m uk-margin-remove-left uk-padding-remove uk-flex">
                  <div class="uk-padding-remove uk-margin-remove-left">
                    <label for="toggle_button">
                      <span v-if="darkmode" class="toggle__label">Dark mode</span>
                      <span v-if="!darkmode" class="toggle__label">Dark mode</span>
                      <input type="checkbox" id="toggle_button" v-model="darkmode">
                      <span class="toggle__switch"></span>
                    </label>
                  </div>
                </div>
                <div class="uk-width-2-3@m uk-margin-remove-left uk-flex uk-flex-right uk-padding-remove" uk-grid>
                  <div class="uk-flex uk-text-small">
                    <span class="uk-flex uk-flex-middle uk-margin-right white">{{ $t("show") }}</span>
                    <div uk-form-custom="target: > * > span:first-child" style="width: 80px">
                      <select v-model="size" @change="listCount()" class="sortBy uk-align-left" style="width: 80px">
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="200">200</option>
                      </select>
                      <button
                          class="uk-button uk-button-default btn-sortBy uk-width-1-1"
                          tabindex="-1">
                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                    <span
                        class="uk-flex uk-flex-middle uk-margin-left white"
                    > {{ $t("entries") }}</span>
                  </div>
                  <form class="uk-search uk-search-default">
                    <input class="uk-input uk-form uk-form-width-large"
                           type="search" v-model="filter" :placeholder="$t('search')"
                           style="border-radius: 10px; height: 40px;">
                  </form>
                  <button type="button" class="qbtn_d uk-button uk-button-small uk-width-1-3@m uk-margin-left"
                          @click="searchFilter">{{ $t("orgaExam.results.search") }}
                  </button>
                  <button
                      class="uk-width-2-3@m qbtn_d uk-button uk-button-small uk-margin-small-left"
                      @click="defaultSetting()">{{ $t("orgaExam.results.default") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="uk-section" style="padding-top: 0px">
          <div class="uk-container">
            <div class="uk-width-1-1 uk-margin-bottom uk-margin-remove-left uk-padding-remove" uk-grid>
              <!--<div class="uk-width-auto uk-padding-remove">-->
              <!--<button type="button" class="uk-button uk-button-primary uk-button-small" @click="resultDownload()"-->
              <!--<span  uk-icon="icon: download"></span>-->
              <!--<span >{{$t("orgaExam.results.result")}}</span></button>-->
              <!--<button type="button" class="uk-button uk-button-primary uk-button-small " @click="extraTimeModal()"-->
              <!-- v-if="examDetail.realExam == 1"><span  uk-icon="icon: add"></span>-->
              <!--<span >Set extra time</span></button>-->
              <!--&lt;!&ndash;                <a href="/uploadingDir/cloud_user_app.apk" >&ndash;&gt;-->
              <!--&lt;!&ndash;                  {{$t('home.linkDown')}} </a>&ndash;&gt;-->
              <!--</div>-->
            </div>


            <div class="uk-child-width-1-6@m uk-child-width-1-4@s uk-grid-small uk-grid-match" uk-grid>
              <template v-for="(exp,index) in examinee">
                <div :key="index">
                  <div class="uk-card uk-card-default uk-card-body uk-padding-small uitem">
                    <div class="uk-card-header uk-padding-remove">
                      <div class="uk-float-left" v-if="exp.alert_cnt > 0">
                                    <span v-if="exp.alert_cnt <= 5" style="background-color: #c7c7c7;padding: 5px"
                                          class="uk-alert uk-alert-danger alrtlbl">{{ exp.alert_cnt }}</span>
                        <span v-if="exp.alert_cnt > 5 && exp.alert_cnt <= 10"
                              style="background-color: rgb(126 214 253);padding: 5px"
                              class="uk-alert uk-alert-danger alrtlbl">{{ exp.alert_cnt }}</span>
                        <span v-if="exp.alert_cnt > 10 && exp.alert_cnt <= 20"
                              style="background-color: rgb(177 231 255);padding: 5px"
                              class="uk-alert uk-alert-danger alrtlbl">{{ exp.alert_cnt }}</span>
                        <span v-if="exp.alert_cnt > 20" style="background-color: #fdcfd8;padding: 5px"
                              class="uk-alert uk-alert-danger alrtlbl">{{ exp.alert_cnt }}</span>
                        <!--<div class="uk-card-badge uk-label">{{exp.alert_cnt}}</div>-->

                      </div>
                      <div class="uk-float-left" v-else>
                        <span style="background-color: #c7c7c7;padding: 5px"
                              class="uk-alert uk-alert-danger alrtlbl">0</span>
                      </div>
                      <h5 class="uk-margin-remove uk-float-right">{{ exp.name }}</h5>
                    </div>
                    <div>
                      <h5 style="overflow: hidden" :title="exp.username"> {{ exp.username }}</h5>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                      <img class="uk-border-circle" style="height: 140px;width: 140px"
                           v-if="exp.image != null && exp.image != ''"
                           :src="`/uploadingDir/${exp.image}`"/>
                      <span class="uk-border-circle" style="height: 140px;width: 140px"></span>
                    </div>
                    <div class="uk-card-footer uk-flex uk-padding-remove" style="border: none">
                      <button type="button" class="uk-button uk-button-primary uk-button-small"
                              v-if="exp.group_id != ''"
                              style="width: 85px; white-space: nowrap"
                              @click="meet(exp.group_id, exp.username, 'front')">
                         {{ $t("front") }}<br> {{ $t("camera") }}
                      </button>
                      <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-small-left"
                              v-if="exp.group_id != ''"
                              style="width: 85px; white-space: nowrap"
                              @click="meet(exp.group_id, exp.username, 'side')">
                         {{ $t("back") }}<br>{{ $t("camera") }}
                      </button>
<!--                      <button type="button" class="uk-button uk-button-text"-->
<!--                              v-if="exp.group_id != ''"-->
<!--                              style="width: 85px;"-->
<!--                              @click="meet(exp.group_id, exp.username)">-->
<!--                        UBL MEET-->
<!--                      </button>-->
                      <!--                      <button type="button" class="uk-button uk-button-text"-->
                      <!--                              style="width: 85px;" v-else-->
                      <!--                              @click="moree(exp, exp.name, exp.user_id, exp.exam_time)">-->
                      <!--                        See more-->
                      <!--                      </button>-->
                    </div>
                  </div>
                </div>

              </template>
            </div>

          </div>

        </section>
      </div>
    </section>

    <section class="uk-section uk-padding-remove ">
      <div class="uk-container">
        <div class="uk-card-footer uk-margin-remove-left uk-padding-remove" uk-grid>
          <div class="uk-margin-top uk-padding-remove uk-text-center">
            <div class="uk-grid-column-large uk-grid-row-large uk-margin-remove uk-padding-remove" uk-grid>
              <div v-for="(item,index) in pages" :key="index" class="uk-padding-remove uk-margin-small-top">
                <button type="button" class="uk-button uk-button-small uk-margin-left " @click="pageselect(item)"
                        style="min-width: 45px;"
                        :class="{'changed': currentPage === item}">{{ item }}
                </button>
              </div>
            </div>
          </div>
          <span class="uk-flex uk-flex-middle uk-width-1-1 uk-padding-remove uk-margin-large-bottom">Showing
            <span v-if="currentPage==0"
                  class="uk-margin-small-left uk-margin-small-right">  {{ currentPage + 1 }}  </span>
            <span v-else class="uk-margin-small-left uk-margin-small-right">  {{ currentPage }}  </span>
            to {{ pages }} of {{ totalElements }} entries</span>
        </div>
      </div>
    </section>

    <div id="download" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;"> Download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">{{ $t("close") }}</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small"
                  @click="closeModal('all')">Download
          </button>
        </div>
      </div>
    </div>
    <div id="videoDownloadSection" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;"> Download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">{{ $t("close") }}</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small"
                  @click="closeModal('all')">Download
          </button>
        </div>
      </div>
    </div>

    <div id="myModal" v-show="moreinfo.display" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <span class="close" @click="moreclose">&times;</span>
        <div>
          <ul class="uk-list uk-list-large uk-list-striped">
            <li><label><b>Name</b></label> : {{ moreinfo.examineeName }}</li>
            <li>
              <div uk-grid>
                <div>
                  <label><b>Average accuracy</b></label> : {{ moreinfo.avg_acc }}
                </div>
                <div>
                  <label><b>Average accuracy</b></label> : {{ moreinfo.avg_acc_id }}
                </div>
              </div>
            </li>
            <li>
              <div uk-grid>
                <div>
                  <label><b>Max accuracy</b></label> : {{ moreinfo.max_acc }}
                </div>
                <div>
                  <label><b>Max accuracy</b></label> : {{ moreinfo.max_acc_id }}
                </div>
              </div>
            </li>
            <li><label><b>Photo</b></label>
              <div uk-grid>
                <div>
                  <img v-if="moreinfo.photo != null && moreinfo.photo != ''" class="uk-responsive-width"
                       :src="`/uploadingDir/accuracy/${moreinfo.photo}`"/>
                </div>
                <div>
                  <img v-if="moreinfo.photo_id != null && moreinfo.photo_id != ''" class="uk-responsive-width"
                       :src="`/uploadingDir/accuracy/${moreinfo.photo_id}`"/>
                </div>
              </div>
            </li>
            <li><label><b>Record</b></label> :
              <button type="button" @click="mediaGetFile(moreinfo.examineeName, moreinfo.examineeId, moreinfo.examtime)"
                      class="uk-button uk-button-primary uk-button-small">Final video see
              </button>
              <button type="button" @click="currentMedia(moreinfo.examineeName, moreinfo.examineeId, moreinfo.examtime)"
                      class="uk-button uk-button-primary uk-button-small">Real time video see
              </button>
            </li>
          </ul>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small" @click="moreclose">
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>

    <div v-show="frameinfo.display" class="modal">
      <!-- Modal content -->
      <div class="modal-content" style="position: relative">
        <span class="close" @click="frameclose" style="position: absolute;top: 0;right: 15px;">&times;</span>
        <div style="min-height: 500px" class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe id="videoframe" style="height: 600px;width: 100%;" class="embed-responsive-item" src=""></iframe>
        </div>
      </div>
    </div>

    <div id="mediaModal" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Media file play</h2>
        </div>
        <div class="uk-modal-body uk-text-center" style="padding-left: 10px;">
          <table class="uk-table uk-table-striped">
            <thead>
            <tr>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center">Name</th>
              <th class="uk-text-center">URL</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in videosUrl" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item }}</td>
              <td>
                <button type="button" class="uk-button uk-button-primary uk-button-small qbtn_b"
                        @click="mediaDownloadURL(item)">
                  Play
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">{{ $t("close") }}</button>
        </div>
      </div>
    </div>

    <div id="studentExtraTime" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Extra time send</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <div class="uk-width-1-1 uk-margin-remove-right" uk-grid>
            <div class="uk-width-3-4 uk-flex">
              <h5 class="uk-text-bold">Extra time : </h5>
              <input type="number" v-model="exam.extraTime" class="uk-input uk-margin-left"
                     style="height: 25px;  width: 80px;">
              <span class="uk-margin-left">minutes</span>
            </div>
            <div class="uk-width-1-4 uk-flex uk-flex-right uk-padding-remove">
              <span class="uk-text-right uk-text-bold">Selected : </span>
              <span class="uk-margin-left  uk-text-danger uk-text-bold">{{ userIds.length }}</span>
            </div>
          </div>
          <table class="uk-table uk-table-responsive uk-table-small">
            <thead style="background: #01b0ff">
            <tr>
              <th style="text-align: center">
                <input type="checkbox" class="uk-checkbox" @click="toggleSelect()" style="border: 1px solid #fff;">
              </th>
              <th style="text-align: center">
                N/A
              </th>
              <th style="text-align: center">
                Exam name
              </th>
              <th style="text-align: center">
                Exam code
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user,ind) in examinee" :key="ind">
              <td>
                <input type="checkbox" class="uk-checkbox" v-model="userIds"
                       @click="select()"
                       v-if="user.examineeName != ''" :value="user.examineeId">
              </td>
              <td>
                {{ ind + 1 }}
              </td>
              <td>
                {{ user.examineeName }}
              </td>
              <td>
                {{ user.examCode }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">{{ $t("close") }}</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small"
                  @click="extraTimeSend()">send
          </button>
        </div>
      </div>
    </div>
    <div id="student" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-center"
              style="font-size: 21px; font-weight: 700;">Student result download</h2>
        </div>
        <div class="uk-modal-body uk-text-center">
          <p style="font-size: 19px;">
            Your download file is ready. <br>
            Please click to download.
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">{{ $t("close") }}</button>
          <button type="button" class="uk-button uk-button-primary uk-button-small"
                  @click="closeModal('student')">Download
          </button>
        </div>
      </div>
    </div>
    <!--<page-footer/>-->
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import ExamService from '@/services/ExamService'
import GroupService from '@/services/GroupService'
import moment from "moment";
import store from '@/store/store'
import axios from 'axios'
import UIkit from "uikit";

export default {
  name: "app",
  components: {},
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "currentUser"]),
    resultQuery() {
      if (this.value) {
        return this.stGroups.filter((item) => {
          return this.value.toLowerCase().split(' ').every(v => item.groupName.toLowerCase().includes(v))
        })
      } else {
        return this.stGroups;
      }
    }
  },
  data() {
    return {
      darkmode: false,
      time: 0,
      isRunning: false,
      interval: null,
      statusfinish: 3,
      frameinfo: {
        display: false,
        url: ""
      },
      filter: '',
      moreinfo: {
        avg_acc: "",
        max_acc: "",
        avg_acc_id: "",
        max_acc_id: "",
        client: "",
        photo: "",
        photo_id: "",
        examineeName: "",
        examineeId: "",
        examtime: "",
        display: false
      },
      examineeResultDetail: [],
      examCode: '',
      options: '',
      examDetail: '',
      examineeName: '',
      exam: {
        examineeName: '',
        examCode: '',
        extraTime: '',
      },
      examinee: [],
      currentPage: 1,
      pages: 0,
      examId: 0,
      ittt: 0,
      indexNumber: 0,
      examCodeSee: '',
      examineeNameSee: '',
      currentExaminee: '',
      currentExamineeOptions: '',
      downloadUrl: '',
      value: '',
      studentUrl: '',
      stGroups: [{groupName: "All group", id: 0}],
      activeGroup: {groupName: "All group", id: 0},
      itemsPerPage: 1,
      videosUrl: [],
      selected: [],
      allSelected: false,
      userIds: [],
      size: 25,
      examineeResult: [],
      detailsAnswerId: '',
      answerInputedData: '',
      localPath: "/ubtexam/teacher",
      // localPath: 'http://localhost:8084/exam/teacher',
      totalElements: 0
    };
  },
  created() {
    this.toggleTimer();
    this.groups()
  },
  mounted() {
    this.examId = this.$route.params.examId;
    this.examDetails();
    this.loaddata(1);
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD')
      // return moment(date).format('YYYY-MM-DD, h:mm:ss')
    }
  },
  methods: {
    statusSearch() {
      this.currentPage = 1;
      this.loaddata(this.currentPage)
    },
    meet(group_id, username, side) {
      var uss = this.currentUser.firstName
      var ssd = this.currentUser.username
      if (side == 'side')
      {
        var kd = group_id + "_" + this.examId + "_tab"
        /* eslint-disable */
        OpenTab(kd, username,uss, ssd);
      }
      else {
        /* eslint-disable */
        var kd = group_id + "_" + this.examId
        OpenMeet(kd, username, uss, ssd);
      }
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval)
      } else {
        this.interval = setInterval(this.incrementTime, 1000)
      }
      this.isRunning = !this.isRunning
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
      this.hours = Math.floor(this.time / 3600);
      this.time %= 3600;
      this.minutes = Math.floor(this.time / 60);
      this.seconds = this.time % 60;
      if (this.time == 30) {
        this.loaddata(1);
        this.time = 0;
      }
    },
    listCount() {
      this.loaddata(1)
    },
    moreclose() {
      this.moreinfo.display = false;
      this.toggleTimer();
    },
    frameclose() {
      document.getElementById('videoframe').src = '';
      this.frameinfo.display = false;
      this.toggleTimer();
    },

    moree(data, name, userId, examtime) {
      this.moreinfo.avg_acc = data.avg_accuracy;
      this.moreinfo.max_acc = data.max_accuracy;
      this.moreinfo.avg_acc_id = data.avg_accuracy_id;
      this.moreinfo.max_acc_id = data.max_accuracy_id;
      this.moreinfo.client = data.client;
      this.moreinfo.photo = data.photo;
      this.moreinfo.photo_id = data.photo_id;
      this.moreinfo.examineeName = name;
      this.moreinfo.examineeId = userId;
      this.moreinfo.examtime = examtime;
      this.moreinfo.display = true;
      this.toggleTimer();
      // UIkit.modal('#moreinfo').show();
    },
    toggleSelect: function () {
      this.userIds = [];
      if (this.allSelected) {
        this.allSelected = false
      } else {
        this.allSelected = true
      }
      if (this.allSelected) {
        for (let i = 0; i < this.examinee.length; i++) {
          if (this.examinee[i].examineeName != '') {
            this.userIds.push(this.examinee[i].examineeId);
          }
        }
      }
    },
    select: function () {
      if (this.allSelected) {
        this.allSelected = false;
      }
    },
    extraTimeModal() {
      UIkit.modal('#studentExtraTime').show()
    },
    async extraTimeSend() {
      try {
        if (this.exam.extraTime != '') {
          var userItems = [];
          if (this.userIds.length > 0) {
            for (let j = 0; j < this.userIds.length; j++) {
              for (let i = 0; i < this.examinee.length; i++) {
                if (this.examinee[i].examineeId != null) {
                  if (this.userIds[j] == this.examinee[i].examineeId) {
                    let item = {
                      ecode: this.examinee[i].examCode,
                      uid: this.examinee[i].examineeId,
                      variant: 'A'
                    };
                    userItems.push(item)
                  }
                }
              }
            }
          } else {
            alert("Examinee not found!")
          }
          const response = await ExamService.extraTimeSet({
            examId: this.examId,
            extraTime: this.exam.extraTime,
            users: userItems
          });

          if (response.data.status == 200) {
            console.log(response);
            alert("nice")
          } else {
            alert("something wrong")
          }
        } else {
          alert("Please put extra time!!")
        }
      } catch (error) {
        console.log(error)
      }
    },
    mediaDownloadURL(item) {
      window.open('https://www.nsdai.org/' + item, '_blank')
    },
    async mediaGetFile(name, loginID, examtime) {
      try {

        this.moreinfo.display = false;

        let formData = new FormData();

        formData.append('ExamName', this.examDetail.examName);
        formData.append('ExamTime', examtime);
        formData.append('VideoQuality', 0.05);
        formData.append('StudentName', name);
        formData.append('StudentID', loginID);
        formData.append('ExamID', this.examId);
        formData.append('IfSaveStillImages', 'False');
        formData.append('SaveStillImagIntervalSec', '10');

        var _this = this;
        axios.post('https://nsdai.org/GetExamDetailForUBT', formData).then(function (response) {
          console.log("recieved response first : ", response.data);
          if (response.data.exam.status == 10) {
            if (response.data.files == null) {
              alert("there is no file");
              _this.toggleTimer();
            } else {

              _this.videosUrl = response.data.files;
              document.getElementById('videoframe').src = 'https://nsdai.org/' + _this.videosUrl[0];
              _this.frameinfo.display = true;
              // var modal = UIkit.modal("#mediaModal");
              // modal.show()
            }
          } else {
            _this.toggleTimer();
            alert("The file upload is not completed")
          }
        });
      } catch (error) {
        _this.toggleTimer();
        console.log(error)
      }
    },

    async currentMedia(name, loginID, examtime) {
      try {
        this.moreinfo.display = false;

        let formData = new FormData();

        formData.append('ExamName', this.examDetail.examName);
        formData.append('ExamTime', examtime);
        formData.append('VideoQuality', 0.05);
        formData.append('StudentName', name);
        formData.append('StudentID', loginID);
        formData.append('ExamID', this.examId);
        formData.append('IfSaveStillImages', 'False');
        formData.append('SaveStillImagIntervalSec', '10');

        var _this = this;
        axios.post('https://nsdai.org/GetExamDetailForUBT', formData).then(function (response) {
          if (response.data.exam.IfSaveStillImages) {
            // document.getElementById('videoframe').src = "https://nsdai.org/exam/StillImageViewer/" + response.data.exam.id;
            // _this.frameinfo.display = true;
            document.getElementById('videoframe').src = "https://nsdai.org/LiveServerRedirect?id=" +
                response.data.exam.id;
            _this.frameinfo.display = true;
          } else {
            _this.toggleTimer();
            alert("Video not found");
          }
        });
      } catch (error) {
        _this.toggleTimer();
        console.log(error)
      }
    },

    searchFilter() {
      this.loaddata(1);
    },
    closeModal(item) {
      if (item == 'all') {
        UIkit.modal('#download').hide();
        window.location.href = '/uploadingDir/report/' + this.downloadUrl
      }
      if (item == 'student') {
        UIkit.modal('#student').hide();
        window.location.href = '/uploadingDir/report/' + this.studentUrl
      }
    },
    async resultDownload() {
      try {

        var offset = new Date().getTimezoneOffset();
        var ncd = offset / 60;

        // eslint-disable-next-line no-unused-vars
        var zone = 'Asia/Seoul';

        if (ncd == '-7') {
          zone = 'Asia/Jakarta';
        }
        if (ncd == '-8') {
          zone = 'Asia/Ulaanbaatar';
        }
        if (ncd == '-9') {
          zone = 'Asia/Seoul';
        }

        const response = await ExamService.getResultDownload({examId: this.examId, tZone: zone});

        if (response.data.status == 200) {
          if (response.data.success == true) {
            this.downloadUrl = response.data.result.downloadUrl;
            UIkit.modal('#download').show()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async groups() {
      try {
        const response = await GroupService.groups({orgid: this.currentUser.organizs[0].organizId});
        this.stGroups = [{groupName: "All group", id: 0}];
        console.log("groups")
        console.log(response.data)
        response.data.map((item) => {
          this.stGroups.push(item)
        })
      } catch (error) {
        console.log(error)
      }
    },
    selectGroup(item) {
      if (this.activeGroup.id != item.id) {
        this.activeGroup = item;
        this.currentPage = 1;
        this.examDetails()
        this.loaddata(this.currentPage)
      }
    },
    async examDetails() {
      try {
        store.commit('setloading', true);
        this.examDetail = {};
        const response = await ExamService.getExamDetails(this.examId,{ grpId: this.activeGroup.id, orgId : this.currentUser.organizs[0].organizId} );
        if (response.data.status == 200) {
          this.examDetail = response.data.result.examDetail
        }
        store.commit('setloading', false);
      } catch (error) {
        console.log(error)
      }
    },

    popUpStudentDetail(userId, examCode, variant) {

      this.$setCookie('utoken', this.getToken);
      this.$setCookie('urole', this.currentUser.authorities[0].authority);
      this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);


      if (window.location.host.includes('kr')) {
        this.$i18n.locale = 'kr'
      }

      let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale;

      window.open(this.localPath + "/examinee_result" + "?lang=" + lang + "&examId=" + this.examId
          + "&examCode=" + examCode + "&variant=" + variant + "&userId=" + userId, '_blank',
          'width=1100,height=750')
    },

    async studentDetail(userId, examCode, username, variant) {
      try {
        this.examineeResult = [];
        this.examineeNameSee = username;
        this.examCodeSee = examCode;
        let items = {
          examId: this.examId,
          examCode: examCode,
          userId: userId,
          variant: variant
        };

        const response = await ExamService.getExamineeResultDetail(items);

        if (response.data.status == 200) {
          if (response.data.success == true) {
            for (let i = 0; i < response.data.result.data.length; i++) {
              let question = response.data.result.data[i];
              question.truetrue = false;

              question.resultData.time = parseInt(question.resultData.time);

              let hours = Math.floor(question.resultData.time / 3600);
              question.resultData.time %= 3600;
              let minutes = Math.floor(question.resultData.time / 60);
              let seconds = question.resultData.time % 60;
              question.resultData.time = hours + ":" + minutes + ":" + seconds;

              if (question.questionType == '2') {
                if (question.resultData.details != null) {
                  for (let j = 0; j < question.resultData.details.length; j++) {
                    let details = question.resultData.details[j];
                    details.optionNumber = '';

                    for (let ii = 0; ii < question.answers.length; ii++) {
                      let qanswer = question.answers[ii];

                      if (details.answerInputedData == qanswer.trueData) {
                        this.trueScore++;
                        qanswer.trueAfalse = 'true';
                        question.truetrue = true
                      }
                    }
                  }
                } else {
                  question.resultData.details = [];
                  question.resultData.like = 0;
                  question.resultData.memo = '';
                  question.resultData.paint = '';
                  question.resultData.score = -1;
                  question.resultData.time = '0:0:0'
                }

              }
              if (question.questionType == '4') {
                if (question.resultData.details != null) {
                  if (question.resultData.score > 0) {
                    question.truetrue = true;
                    this.trueScore++
                  }
                } else {
                  question.resultData.details = [];
                  question.resultData.like = 0;
                  question.resultData.memo = '';
                  question.resultData.paint = '';
                  question.resultData.score = -1;
                  question.resultData.time = '0:0:0'
                }
              }

              if (question.questionType == '3') {
                let multipleCount = 0;
                let trueCount = 0;

                for (let qa = 0; qa < question.answers.length; qa++) {

                  let qqAnswer = question.answers[qa];

                  if (qqAnswer.answerFlag == 'true') {
                    trueCount++
                  }
                }
                if (question.resultData.details != null) {
                  for (let j = 0; j < question.resultData.details.length; j++) {
                    let details = question.resultData.details[j];
                    details.optionNumber = '';
                    this.answerInputedData = details.answerInputedData;

                    for (let ii = 0; ii < question.answers.length; ii++) {
                      let qanswer = question.answers[ii];
                      qanswer.trueAfalse = 'false';

                      if (qanswer.id == details.answerId) {
                        details.optionNumber = qanswer.optionNumber;
                        details.answerFlag = qanswer.answerFlag
                      }
                      if (qanswer.id == details.answerId && details.answerFlag == 'true') {
                        multipleCount++
                      }
                    }
                  }
                } else {
                  question.resultData.details = [];
                  question.resultData.like = 0;
                  question.resultData.memo = '';
                  question.resultData.paint = '';
                  question.resultData.score = -1;
                  question.resultData.time = '0:0:0'
                }

                if (trueCount != 0 && multipleCount != 0) {
                  if (trueCount == multipleCount) {
                    question.truetrue = true;
                    this.trueScore++
                  }
                }
              }
              if (question.questionType == '1') {
                if (question.resultData.details != null) {
                  for (let j = 0; j < question.resultData.details.length; j++) {
                    let details = question.resultData.details[j];
                    this.detailsAnswerId = details.answerId; //asuultiinh
                    this.answerInputedData = details.answerInputedData;
                    details.optionNumber = '';
                    details.answerFlag = '';
                    for (let ii = 0; ii < question.answers.length; ii++) {

                      let qanswer = question.answers[ii];
                      qanswer.trueAfalse = 'false';
                      if (qanswer.id == this.detailsAnswerId) {
                        qanswer.trueAfalse = 'true';
                        details.optionNumber = qanswer.optionNumber
                      }
                      if (qanswer.trueAfalse == 'true' && qanswer.answerFlag == 'true') {
                        this.trueScore++;
                        question.truetrue = true
                      }
                      // if (this.answerInputedData == qanswer.trueData) {
                      // 	this.trueScore++
                      // }
                    }
                  }
                } else {
                  question.resultData.details = [];
                  question.resultData.like = 0;
                  question.resultData.memo = '';
                  question.resultData.paint = '';
                  question.resultData.score = -1;
                  question.resultData.time = '0:0:0'
                }
              }
            }
            this.examineeResult = response.data.result.data;
            UIkit.modal('#studentPop').show()

          } else {
            alert(response.data.message)
          }
        } else {
          alert("response status failed !!")
        }

      } catch (error) {
        console.log("error message");
        console.log(error)
      }
    },
    async answerResults(exp, data, name) {
      try {
        this.examineeResult = [];
        this.examCodeSee = exp;
        this.examineeNameSee = name;
        let items = {
          examId: this.examId,
          examCode: exp,
          option: data,
          examineeName: name
        };
        const response = await ExamService.getExamineeResultDetail(items);

        for (let i = 0; i < response.data.result.questionResults.length; i++) {
          let item = response.data.result.questionResults[i];

          item.answerFlag = false;
          item.optionNumber = '';
          item.checkedAnswer = '';
          item.examineeAnswer = '';
          item.corQuestion = 0;

          if (item.questionType == '1') {
            for (let cc = 0; cc < item.canswers.length; cc++) {
              let aa = item.canswers[cc];

              aa.optionNumber = '';

              for (let j = 0; j < item.qanswers.length; j++) {
                let hh = item.qanswers[j];

                aa.optionNumber = hh.optionNumber;

                if (hh.answerId == aa.checkedId) {
                  if (hh.answerFlag == 'true') {
                    item.answerFlag = true;
                    console.log(item.answerFlag);
                  } else {
                    item.answerFlag = false;
                    console.log(item.answerFlag);
                  }
                }

              }
              item.examineeAnswer = aa.inputedData
            }
          }
          if (item.questionType == '3') {
            let multipleCount = 0;
            let trueCount = 0;

            for (let j = 0; j < item.qanswers.length; j++) {
              let hh = item.qanswers[j];

              if (hh.answerFlag == 'true') {
                trueCount++
              }

              for (let cc = 0; cc < item.canswers.length; cc++) {
                let aa = item.canswers[cc];

                aa.optionNumber = '';
                aa.optionNumber = hh.optionNumber;

                if (hh.answerId == aa.checkedId) {
                  if (hh.answerFlag == 'true') {
                    multipleCount++
                  } else {
                    multipleCount = 1000
                  }

                }

              }
              if (multipleCount == trueCount) {
                item.answerFlag = true
              } else {
                item.answerFlag = false
              }
            }
          }
        }
        this.examineeResult = response.data.result.questionResults;
        if (response.status == 200) {
          for (let q = 0; q < this.examineeResultDetail.length; q++) {
            let main = this.examineeResultDetail[q];
            if (main.answerFlag == true) {
              // this.examinee.corQues++
            }
          }

        } else {
          alert("error")
        }
      } catch (error) {
        console.log(error)
      }
    },
    pageselect(item) {
      this.currentPage = item;
      this.loaddata(this.currentPage)
    },
    async loaddata(page) {
      try {
        if (page > 0) {
          page = page - 1;
        }
        let item = {
          page: page,
          examId: this.examId,
          name: this.exam.examineeName,
          examCode: this.exam.examCode,
          filter: this.filter,
          sortfield: '',
          size: this.size,
          groupId: this.activeGroup.id,
          ascordesc: '',
          finish: this.statusfinish
        };
        store.commit('setloading', true);
        const response = await ExamService.getExamineeResultGrid(item);
        this.examinee = response.data.content;
        this.pages = response.data.totalPages;
        this.totalElements = response.data.totalElements;

        if (!this.examinee.length) {
          this.isLoading = false;
        }
        store.commit('setloading', false);

      } catch (error) {
        this.error = error.response.data.message;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    onChange: function () {
      this.loaddata(1);
    },

    defaultSetting() {
      window.location.reload(true);
    },
  }
};


</script>


<style scoped>
.darkback {
  background: #000000 !important;
}

.darkback tbody {
  background: #171616 !important;
}

.darkback tbody tr {
  border: 1px solid #5f5f5f;
  color: #eee;
}

.darkback .qList {
  color: #cccbcb;
}

.darkback label {
  color: #fff;
}

.darkback #myModal label {
  color: #666666;
}

.darkback .tit {
  color: #cccbcb;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.darkback .tit2 {
  text-align: left;
  font-size: 14px;
}

.darkback .qbtn_d {
  background: #171616 !important;
  color: #b1adad;
}

.darkback .uk-card-default .uk-card-footer {
  border-top: 1px solid rgba(177, 173, 173, 0.21);
}

.darkback .uk-card-default .uk-card-header {
  border-bottom: 1px solid rgba(177, 173, 173, 0.21);
}

.uitem {
  border-radius: 5px;
}

.darkback .uitem {
  background: #171616;
  color: #bfbdbd;
}

.darkback .uitem button {
  color: #bfbdbd;
}

.darkback .uitem h5 {
  color: #bfbdbd;
}

.darkback .uk-table td.subtit {
  border: none !important;
}

.darkback .uk-table tr.col {
  border: none;
}

.darkback .white {
  color: #fff;
}

.alrtlbl {
  font-size: 18px;
  display: block;
  margin-bottom: 2px;
  min-width: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

table tr .hidden {
  display: none;
}

.hidden {
  border-top: 1px solid #dee2fe;
  border-bottom: 1px solid #dee2fe;
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.scroll {
  height: 400px;
  overflow: auto;
}

.uk-label.uk-label-primary {
  background-color: #01b0ff
}

.uk-button-primary {
  background-color: #01b0ff
}

.uk-table td.subtit {
  border: 1px solid #dee2e6;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  /* color: #404348; */
  font-size: 14px;
  /* background-color: #eeeeef; */
}

.uk-table tr {
  border: 1px solid #dee2e6;
}

.uk-table td.subtits {
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  padding-left: 10px;
  /* color: #404348; */
  font-size: 14px;
  /* background-color: #eeeeef; */
}

.uk-table tr td {
  vertical-align: middle;
}

.uk-table tr.col {
  border-bottom: 1px solid #dee2fe;
  vertical-align: middle;
  border-top: 1px solid #dee2fe;
  font-size: 13px;
}

.uk-table th {
  vertical-align: middle;
  color: #fff;
}

.qList {
  height: 20px;
  font-size: 1.4em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.qLists {
  border-bottom: 3px solid rgb(1, 176, 255);
  padding-bottom: 10px;
  width: auto;
  height: 20px;
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(1, 176, 255);
}

.detailsStudent {
  border: 1px solid #dee2e6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sortBy {
  opacity: 0;
  background: #fff;
}

.btn-sortBy {
  outline: none;
  padding: 0px 5px 0px 5px;
  background: #fff;
}

.qbtn_d {
  width: 120px;
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}

.tit {
  color: #0276ac;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.tit2 {
  text-align: left;
  font-size: 15px;
}

@media (min-width: 1200px) {
  .uk-card-footer {
    padding: 0px 35px;
  }
}

.qbtn_b {
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}


/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  padding: 15px 10px;
  width: 700px;
  max-width: calc(100% - 0.01px) !important; /* Full width */
  margin-bottom: 100px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.changed {
  background: #0d9df9;
  color: white;
}
</style>
